<template>
  <section id="signup">
    <v-card class="v-card--auth pa-5" outlined max-width="500">
      <v-container pa-0 grid-list-xl>
        <h1 class="headline font-weight-regular primary--text">
          Opret cvr.dev konto
        </h1>
        <v-form ref="form" v-model="form" style="padding: 1rem 1rem 0rem 1rem" class="mb-5"
          @keyup.native.enter="register">
          <v-container pa-0>
            <v-row>
              <v-text-field autofocus v-model="email" @input="setAuthEmail"
                :rules="[rules.required('Indtast email adresse')]" label="Email" name="email" persistent-hint />
            </v-row>
            <v-row>
              <v-text-field v-model="password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required('Indtast kodeord')]" :type="show ? 'text' : 'password'"
                label="Kodeord, mindst 6 tegn" @click:append="show = !show" />
            </v-row>
            <v-row>
              <v-text-field v-model="confirmPassword" :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required('Gentag kodeord'), rules.confirm]" :type="showConfirm ? 'text' : 'password'"
                label="Gentag kodeord" @click:append="showConfirm = !showConfirm" />
            </v-row>
            <v-row>
              <v-checkbox v-model="termsAccepted" :rules="[rules.required('Du skal godkende betingelserne')]">
                <template v-slot:label>
                  <div>
                    Jeg har læst og accepterer <br />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <router-link :to="{ name: 'terms' }" v-slot="{ href, navigate }">
                          <a :href="href" v-on="on" @click.stop @click="navigate" target="_blank">betingelserne</a>
                        </router-link>
                      </template>
                      Åbner i et nyt vindue
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-row>
            <v-row>
              <v-checkbox v-model="reklamebeskyttelseAccepted" :rules="[
                rules.required(
                  'Du skal acceptere loven om reklamebeskyttelse'
                ),
              ]">
                <template v-slot:label>
                  <div>
                    Jeg forstår og vil følge
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a v-on="on" @click.stop :href="virkTermsUrl" target="_blank">loven <br />
                          om reklamebeskyttelse</a>
                      </template>
                      Åbner i et nyt vindue
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-row>
            <v-row>
              <v-btn color="accent" @click="register" :disabled="!createAccountEnabled" v-bind:loading="isLoading">Opret
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </v-card>
  </section>
</template>

<script>
import config from "@/config.js";
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    const data = {
      virkTermsUrl: config.VirkTermsAndConditionsUrl,
      isLoading: false,
      form: false,
      termsAccepted: false,
      reklamebeskyttelseAccepted: false,
      email: this.$store.state.authEmail,
      password: undefined,
      confirmPassword: undefined,
      rules: {
        required: (msg) => (v) => !!v || msg,
        confirm: (v) => (v ? v === this.password : "Kodeord matcher ikke"),
      },
      show: false,
      showConfirm: false,
    };
    return data;
  },
  computed: {
    createAccountEnabled() {
      return this.termsAccepted && this.reklamebeskyttelseAccepted;
    },
  },
  methods: {
    ...mapActions("cognito", ["registerUser"]),
    ...mapMutations(["setSnackbar", "setAuthEmail"]),

    register() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;

      this.registerUser({
        username: this.email,
        password: this.password,
        attributes: {
          email: this.email,
        },
      })
        .then(() => {
          this.setSnackbar({
            type: "success",
            msg: "Konto oprettet. Vi har sendt dig en valideringsemail",
          });
          this.$router.push({ name: "signin", query: { message: "Vi har sendt dig en valideringsemail.\nKlik venligst på linket i mailen for at validere din email." } });
        })
        .catch((err) => {
          this.setSnackbar({
            type: "error",
            msg: err.message,
            timeout: 10000,
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
